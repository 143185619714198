import {
  Button,
  ButtonWhatsapp,
  Card,
  Container,
  If,
  RoundIcon,
  SliderController,
  Title
} from '@/components'

import { useContact } from './useContact'

import { contacts } from './constants'

import styles from './Contact.module.scss'

export const Contact = ({ variant }) => {
  const {
    visibleCardIndex,
    benefitsListRef,
    benefitsItemsRefs,
    isNotDesktop,
    onScroll,
    onClickController
  } = useContact()

  return (
    <section className={`${styles.contact} ${styles[`contact--${variant}`]}`}>
      <Container>
        <Title
          align="center"
          color={variant === 'secondary' && 'white'}
          text={
            <>
              Ainda ficou com dúvidas?
              <br />
              <b>Contate um de nossos canais de atendimento</b>
            </>
          }
        />

        <If condition={isNotDesktop}>
          <SliderController
            size="medium"
            variant="tertiary"
            section="contact"
            customClass={styles.contact__controllers}
            length={contacts.length}
            visibleElementIndex={visibleCardIndex}
            onClick={onClickController}
          />
        </If>

        <div
          ref={benefitsListRef}
          className={styles.contact__list}
          onScroll={onScroll}
        >
          {contacts.map(
            ({ id, icon, title, text, button, phone_number }, index) => (
              <Card
                size="medium"
                key={`contact-card-${index}`}
                customClass={styles.card}
                ref={(ref) => (benefitsItemsRefs.current[index] = ref)}
              >
                <RoundIcon
                  icon={icon}
                  iconColor="white"
                  size="large"
                  border="thick"
                  bgColor="red"
                />

                <h4 className={styles.card__title}>{title}</h4>
                <p className={styles.card__text}>{text}</p>

                {index === 0 && (
                  <ButtonWhatsapp
                    id="contact-whatsapp-button"
                    customClass={styles.card__button}
                  >
                    Contrate pelo WhatsApp
                  </ButtonWhatsapp>
                )}

                {index !== 0 && (
                  <Button
                    tagName="a"
                    rel="noreferrer"
                    target="_blank"
                    id={id}
                    href={button.url}
                    className={styles.card__button}
                  >
                    {button.text}
                  </Button>
                )}

                <p className={styles.card__title}>{phone_number}</p>
              </Card>
            )
          )}
        </div>
      </Container>
    </section>
  )
}
