import {
  CUSTOMER_SERVICE_URL,
  POSITIVE_SUBSCRIPTION_URL,
  SAC_CHAT_URL
} from '@/constants/links'
import {
  CALLCENTER_PHONE_NUMBER,
  CONSUMER_PHONE_NUMBER,
  POSITIVE_SUBSCRIPTION_PHONE_NUMBER,
  SAC_PHONE_NUMBER
} from '@/constants/phones'

export const contacts = [
  {
    icon: 'equifax-phone-chat',
    title: 'Central de vendas',
    text: 'Para você que quer conhecer mais sobre as soluções e contratar um plano.',
    phone_number: CALLCENTER_PHONE_NUMBER
  },
  {
    id: 'contact-sac-button',
    icon: 'equifax-phone',
    title: 'Atendimento Empresas',
    text: 'Para você que já é cliente Equifax | BoaVista e precisa de suporte agora.',
    phone_number: SAC_PHONE_NUMBER,
    button: {
      text: 'Fale conosco',
      url: SAC_CHAT_URL
    }
  },
  {
    id: 'contact-consumer-button',
    icon: 'equifax-phone-ring',
    title: 'Atendimento Consumidor',
    text: 'Para você que quer consultar seu Score ou limpar o nome',
    phone_number: CONSUMER_PHONE_NUMBER,
    button: {
      text: 'Fale conosco',
      url: CUSTOMER_SERVICE_URL
    }
  },
  {
    id: 'contact-positiveSubscription-button',
    icon: 'equifax-thumb-up',
    title: 'Cadastro Positivo',
    text: 'Para você que quer entrar no programa Cadastro Positivo e ser reconhecido como bom pagador no mercado',
    phone_number: POSITIVE_SUBSCRIPTION_PHONE_NUMBER,
    button: {
      text: 'Saiba mais',
      url: POSITIVE_SUBSCRIPTION_URL
    }
  }
]
