import { useRouter } from 'next/navigation'

import isClient from '@equifax-ui/utils/browser/isClient'

import { Container, PlanList, Subtitle, Tag, Title } from '@/components'

import styles from './styles.module.scss'

export const Plans = () => {
  const router = useRouter()

  const onSelectPlan = (planId) => {
    isClient() && sessionStorage.setItem('bvs-plan', planId)
    router.push('/formulario/dados-basicos')
  }

  return (
    <section id="planos" className={styles.plans}>
      <Container>
        <Tag text="Planos Pós-pago" />

        <Title
          text={
            <>
              Escolha o <b>melhor plano para o seu négócio</b>
            </>
          }
          customClass={styles.plans__title}
        />

        <Subtitle
          variant="small"
          text="Todos os planos são sujeitos a análise e incluem os relatórios básico, intermediário e completo de Pessoa Física e Pessoa Jurídica."
          customClass={styles.plans__subtitle}
        />

        <PlanList onClickCTA={(planID) => onSelectPlan(planID)}></PlanList>
      </Container>
    </section>
  )
}
