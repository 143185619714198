const scrollTriggerAnimation = (selector, options = {}) => {
  let elements = document.querySelectorAll(selector)
  elements = Array.from(elements)

  elements.forEach((element) => {
    addObserver(element, options)
  })
}

const addObserver = (element, options) => {
  const positionOfElements = element.getBoundingClientRect().y
  const currentScroll = window.scrollY
  const animateContentBelowCurrentScroll = currentScroll > positionOfElements

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting || animateContentBelowCurrentScroll) {
        entry.target.classList.add('animate')
        observer.unobserve(entry.target)
      }
    })
  }, options)

  observer.observe(element)
}

export default scrollTriggerAnimation
