import { useState } from 'react'

import { HOME_FAQ_DATA, POSTPAID_FAQ_DATA } from './constants'

export const useHelp = ({ shouldRenderFilters }) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(0)
  const [activeAccordionIndex, setActiveAccordionIndex] = useState()

  const faqItems = shouldRenderFilters
    ? HOME_FAQ_DATA[activeFilterIndex]
    : POSTPAID_FAQ_DATA

  const handleTrigger = (accordionIndex) => {
    if (activeAccordionIndex === accordionIndex) {
      setActiveAccordionIndex(undefined)
      return
    }

    setActiveAccordionIndex(accordionIndex)
  }

  return {
    faqItems,
    activeFilterIndex,
    activeAccordionIndex,
    setActiveFilterIndex,
    handleTrigger
  }
}
