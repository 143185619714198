import { useRef, useState } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

export const useContact = () => {
  const [visibleCardIndex, setVisibleCardIndex] = useState(0)

  const benefitsListRef = useRef()
  const benefitsItemsRefs = useRef([])

  const isNotDesktop = useMediaQuery('screen and (max-width: 1024px)')

  const isOnScreen = (element) => {
    const { x } = element.getBoundingClientRect()

    return x > 0 && x < 80
  }

  const onScroll = () => {
    const newVisibleCardIndex = benefitsItemsRefs.current.findIndex((benefit) =>
      isOnScreen(benefit)
    )

    if (newVisibleCardIndex >= 0 && newVisibleCardIndex !== visibleCardIndex) {
      setVisibleCardIndex(newVisibleCardIndex)
    }
  }

  const onClickController = (direction) => {
    const directions = {
      left: visibleCardIndex - 1,
      right: visibleCardIndex + 1
    }

    const nextVisibleCard = benefitsItemsRefs.current[directions[direction]]
    const { top, left } = nextVisibleCard.getBoundingClientRect()

    benefitsListRef.current.scrollBy({
      top,
      left,
      behavior: 'smooth'
    })
  }

  return {
    visibleCardIndex,
    benefitsListRef,
    benefitsItemsRefs,
    isNotDesktop,
    onScroll,
    onClickController
  }
}
