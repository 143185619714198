import { Container, HiringSteps, Title } from '@/components'

import { HIRING_STEPS } from './constants'

import styles from './Hiring.module.scss'

export const Hiring = () => {
  return (
    <section className={styles.hiring}>
      <Container>
        <Title
          text={
            <>
              <b>Contratar seu plano</b> é muito fácil!
            </>
          }
        />

        <div className={styles.hiring__wrapper}>
          <HiringSteps hasLine steps={HIRING_STEPS} />
        </div>
      </Container>
    </section>
  )
}
