'use client'

import { useEffect } from 'react'

import scrollTriggerAnimation from '@equifax-ui/utils/animation/scrollTriggerAnimation'
import { setGa4ClientId } from '@/utils/ga4ClientId'
import { setSourceUrl } from '@/utils/sourceUrl'
import { setDocumentReferrer } from '@/utils/referrer'

const usePageLayout = () => {
  useEffect(() => {
    document.addEventListener('scroll', () =>
      scrollTriggerAnimation('.animate-init', {})
    )
    setGa4ClientId()
    setSourceUrl()
    setDocumentReferrer()
  }, [])
}
export default usePageLayout
