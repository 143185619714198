import Image from 'next/image'

import { Accordion, Container, Filters, If, Tag, Title } from '@/components'

import Man from '/public/images/faq-man.webp'

import { useHelp } from './useHelp'

import { FILTERS } from './constants'

import styles from './Help.module.scss'

export const Help = ({ shouldRenderFilters }) => {
  const {
    faqItems,
    activeFilterIndex,
    activeAccordionIndex,
    setActiveFilterIndex,
    handleTrigger
  } = useHelp({
    shouldRenderFilters
  })

  return (
    <section id="ajuda" className={styles.help}>
      <Container>
        <Tag text="Ajuda" />

        <Title
          customClass={styles.help__title}
          text={
            <>
              Ficou com alguma dúvida? <br />
              <b> A Equifax | BoaVista responde</b>
            </>
          }
        />

        <div className={styles.help__wrapper}>
          <If
            condition={shouldRenderFilters}
            renderIf={
              <Filters
                variant="secondary"
                section="help"
                filters={FILTERS}
                activeFilterIndex={activeFilterIndex}
                setActiveFilterIndex={setActiveFilterIndex}
              />
            }
            renderElse={
              <div className={styles.help__image}>
                <Image
                  src={Man}
                  alt="homem com tablet"
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </div>
            }
          />

          <div>
            {faqItems.map(({ title, content }, index) => (
              <Accordion
                key={`${title}-${index}`}
                index={index}
                title={title}
                content={content}
                isActiveItem={activeAccordionIndex === index}
                handleTrigger={handleTrigger}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}
