import { POSITIVE_CONSUMER_URL } from '@/constants/links'

export const FILTERS = [
  { icon: 'equifax-alert', title: 'Sobre a Equifax' },
  { icon: 'equifax-report', title: 'Planos pós-pago' },
  { icon: 'money-coin', title: 'Recargas Pré-pago' },
  { icon: 'equifax-user-business', title: 'Consultas de CNPJ e CPF' },
  { icon: 'equifax-light-bulb', title: 'Soluções' }
]

export const POSTPAID_FAQ_DATA = [
  {
    title: 'Quem deveria contratar os serviços da Equifax | BoaVista?',
    content: (
      <p>
        Empresas de todos os portes e segmentos que precisam tomar decisão de
        conceder crédito ou vender a prazo.
      </p>
    )
  },
  {
    title: 'O que preciso para contratar o serviço da Equifax | BoaVista?',
    content: (
      <>
        <p>
          Como é necessário ser Pessoa Jurídica para contratar o serviço da
          Equifax | BoaVista, precisamos dos dados da empresa e da pessoa física
          que está contratando em nome desta empresa.
        </p>
        <p>
          <strong>Dados da empresa contratante:</strong> CNPJ.
        </p>
        <p>
          <strong>Dados da pessoa física contratante:</strong> Nome, CPF, Email,
          Telefone e Data de Nascimento.
        </p>
      </>
    )
  },
  {
    title: 'Como consultar CPF e CNPJ pode ajudar minha empresa?',
    content: (
      <p>
        Conhecendo os débitos e indicadores de consumo que o seu cliente possui,
        sua empresa reduz a chance de fechar negócio com clientes que possuem
        restrições e evita gastos futuros com cobrança e negativação. A Equifax
        | BoaVista fornece dados relevantes que te ajudam a tomar decisões mais
        assertivas.
      </p>
    )
  },
  {
    title: 'O que preciso para realizar uma consulta?',
    content: (
      <p>
        Após contratar o plano que melhor se encaixe na sua necessidade, você
        receberá um login e senha para acessar o portal. Após logar, para
        realizar a consulta será necessário apenas o número do documento,
        podendo ser CPF para Pessoa Física ou CNPJ para Pessoa Jurídica.
      </p>
    )
  },
  {
    title: 'Quais as informações disponíveis na Consulta de CPF?',
    content: (
      <p>
        Dados cadastrais, restritivos e comportamentais; Score e recomendação de
        crédito; e Indicadores positivos como faturas em atraso, contratos
        recentes, pontualidade e adiantamento de pagamentos e grau de
        comprometimento futuro.
      </p>
    )
  },
  {
    title: 'Quais as informações disponíveis na Consulta de CNPJ? ',
    content: (
      <p>
        Dados cadastrais e restritivos; Participações e quadro societário; Score
        e recomendação de crédito; Indicadores positivos como crédito obtido,
        pontualidade de pagamentos e comprometimento futuro.
      </p>
    )
  },
  {
    title: 'O que é o Score?',
    content: (
      <p>
        O Score é a sua pontuação de crédito. Todas as contas em seu nome, pagas
        ou não, fazem parte dessa nota. As empresas usam o Score para analisar o
        risco e conceder crédito. A pontuação varia de 1 a 1.000 e quanto maior
        melhor.
      </p>
    )
  }
]

export const HOME_FAQ_DATA = [
  [
    {
      title: 'Quem deveria contratar os serviços da Equifax | BoaVista?',
      content:
        'Empresas de todos os portes e segmentos, principalmente aquelas que precisam tomar decisão de conceder crédito ou vender a prazo.'
    },
    {
      title: 'O que preciso para contratar os serviços da Equifax | BoaVista?',
      content:
        'É necessário ser Pessoa Jurídica, então precisamos do CNPJ da empresa e da pessoa física que está contratando em nome desta empresa (Nome, CPF, Email, Telefone, Data de Nascimento).'
    },
    {
      title: 'O que preciso para realizar uma consulta?',
      content:
        'Ao contratar o plano que melhor se encaixe na sua necessidade, você receberá login e senha para acessar o portal. Após logar, para realizar a consulta será necessário apenas o número CPF para Pessoa Física ou CNPJ para Pessoa Jurídica que você deseja consultar.'
    },
    {
      title: 'Por que deveria contratar os serviços da Equifax | BoaVista?',
      content:
        'Como administradora do SCPC e Gestora de Banco de Dados do Cadastro Positivo, trabalhamos com dados exclusivos, confiáveis e atualizados sobre a pessoa física e jurídica, colaborando com o mercado de crédito e auxiliando consumidores e empresas a realizarem negócios seguros por meio de soluções para todas as etapas de negócios.'
    },
    {
      title: 'A Equifax | BoaVista é uma empresa segura? ',
      content:
        'Sim, somos uma empresa segura. Estamos no mercado desde 2010, e somos certificados pelas normas ISO 9001 - gestão da qualidade, ISO 27001 - gestão da segurança da informação e 27001 - gestão da privacidade.'
    },
    {
      title:
        'Não tenho CNPJ, posso contratar os serviços da Equifax | BoaVista?',
      content:
        'Não, nossas soluções são destinadas apenas à empresas, e portanto, é necessário ter CNPJ para contratar.'
    },
    {
      title: 'O que é um Bureau de Crédito?',
      content:
        'Um Bureau de Crédito (ou Birô de Crédito) é uma empresa que coleta, armazena e gerencia informações de indivíduos e empresas, como débitos, histórico de crédito e comportamento de pagamento. Essas informações são usadas por instituições financeiras e empresas para avaliar a capacidade de crédito de uma pessoa ou empresa, ajudando na tomada de decisões sobre concessão de crédito, empréstimos, financiamentos, e outros serviços financeiros.'
    },
    {
      title: 'Quem é a Equifax no mundo?',
      content:
        'A Equifax, fundada em 1899 em Atlanta, EUA, e é um dos três maiores bureaus de crédito do mundo. Somos dedicados a ajudar as pessoas a viverem sua melhor vida financeira. Como líder global em dados, análises e tecnologia, fornecemos insights que impulsionam decisões, capacitando indivíduos e empresas a avançarem em suas missões com segurança. '
    }
  ],
  [
    {
      title: 'Posso cancelar a qualquer momento?',
      content:
        'Sim! Os planos não têm fidelidade, e você pode cancelar quando quiser. '
    },
    {
      title:
        'Preciso escolher qual produto quero consultar antes de contratar o plano pós-pago?',
      content:
        'Não, todos os nossos planos dão acesso aos relatórios de pessoa física e pessoa jurídica. Você escolhe o relatório mais adequado, e realiza a consulta. Conforme você for usando, vamos debitando do valor do seu plano o preço de cada consulta.'
    },
    {
      title: 'Qual a diferença entre os planos pós-pago e o pré-pago? ',
      content:
        'As consultas no plano pós-pago são mais baratas do que nas recargas pré-pagas, ajudando você a economizar! Além disso, os planos pós-pago podem incluir serviços de notificação e negativação de clientes inadimplentes, aumentando suas chances de recuperação de dívidas. O pós-pago também oferece relatórios mais completos, com informações positivas e histórico de consumo e pagamento, não apenas dados restritivos.'
    },
    {
      title:
        'Posso fazer consultas adicionais à quantidade permitida pelo meu plano? ',
      content:
        'Sim, você pode fazer mais consultas do que a quantidade inclusa no seu plano. Essas consultas adicionais serão cobradas na fatura ao final do mês pelo mesmo valor da consulta unitária do seu plano contratado, sem surpresas.'
    },
    {
      title: 'Quais são as formas de pagamento do plano contratado?',
      content:
        'Você receberá por e-mail um boleto junto com a fatura na data de fechamento de cada mês.'
    },
    {
      title: 'Quando serei cobrado?',
      content:
        'A primeira fatura será enviada por e-mail em até 60 dias após a contratação do seu plano, dependendo da data da contratação. A partir da segunda fatura, você receberá mensalmente por e-mail uma nova fatura, após o fechamento. '
    }
  ],
  [
    {
      title: 'Quando serei cobrado?',
      content:
        'Nas recargas pré pagas, não há faturas ou vínculos fixos. Você recarrega quando quiser, o quanto quiser.'
    },
    {
      title: 'Como funciona o pacote de créditos?',
      content:
        'Após finalizar a compra de um dos pacotes de crédito, você receberá acesso ao nosso portal e poderá consultar os relatórios de pessoa física e pessoa jurídica. Lembre-se: ao realizar cada consulta, o valor será descontado do seu pacote de crédito.'
    },
    {
      title: 'Quais são as formas de pagamento?',
      content:
        'O pagamento é feito com cartão de crédito na hora de efetuar a recarga.'
    },
    {
      title: 'Posso cancelar a qualquer momento?',
      content:
        'Sim, como é um modelo pré-pago, não tem fidelidade e você pode parar de recarregar quando quiser.'
    },
    {
      title: 'Quanto tempo dura o crédito?',
      content: '6 meses a partir da data da recarga.'
    }
  ],
  [
    {
      title: 'Quais as informações disponíveis na Consulta de CPF?',
      content:
        'Dados cadastrais, restritivos e comportamentais; Score e recomendação de crédito; e Indicadores positivos referentes a pontualidade e adiantamento de pagamentos, contratos recentes, pontualidade e adiantamento de pagamentos e grau de comprometimento futuro.'
    },
    {
      title: 'Quais as informações disponíveis na Consulta de CNPJ?',
      content:
        'Dados cadastrais e restritivos; Participações e quadro societário; Score e recomendação de crédito; Indicadores positivos referentes a crédito obtido, pontualidade de pagamentos e comprometimento futuro.'
    },
    {
      title: 'O que é o Score?',
      content:
        'O Score é a uma pontuação de crédito que indica a probabilidade de uma pessoa ou empresa pagar suas contas em dia. As contas em seu nome, pagas ou não, fazem parte do cálculo dessa nota. As empresas usam o Score para analisar o risco e conceder crédito. A pontuação geralmente varia de 1 a 1.000 e quanto maior melhor.'
    },
    {
      title:
        'Por que as informações dos relatórios de pessoa física e jurídica são importantes para o meu negócio?',
      content:
        'Porque elas te ajudam a conhecer quem negocia com a sua empresa, antes mesmo de fechar negócio. A Equifax | BoaVista, possui uma das maiores bases de identificação, endereço e telefones do Brasil, reduzindo o risco de fraudes nos negócios e facilitando a localização dos clientes.  Os relatórios também mostram os débitos do consumidor, assim sua empresa reduz a chance de fechar negócio com clientes que possuem restrições e evita gastos futuros com cobrança e negativação. Com as informações comportamentais, também é possível avaliar o comportamento do consumidor no mercado e identificar alterações ou suspeitas de fraude.'
    },
    {
      title: 'Posso consultar o meu CPF? E o CPF de terceiros?',
      content: (
        <>
          As consultas de CPF da Equifax | BoaVista permitem que as empresas
          consultem qualquer CPF de terceiros do Brasil. Para consultar seu
          próprio CPF, você pode acessar o{' '}
          <a href={POSITIVE_CONSUMER_URL} target="_blank" rel="noreferrer">
            Consumidor Positivo
          </a>{' '}
          e consultar grátis o seu CPF.
        </>
      )
    },
    {
      title: 'Qual é a vantagem das consultas positivas?',
      content:
        'As consultas que consideram informações do Cadastro Positivo são mais completas, e  permitem que você faça previsões mais assertivas, principalmente sobre consumidores ou empresas que não possuem restrições, melhorando a segurança das suas concessões de crédito. Elas só estão disponíveis pelo plano pós-pago. '
    },
    {
      title: 'Como consultar CPF e CNPJ pode ajudar minha empresa?',
      content:
        'Conhecendo os débitos e indicadores de consumo que o seu cliente possui, sua empresa reduz a chance de fechar negócio com clientes que possuem restrições e evita gastos futuros com cobrança e negativação.'
    },
    {
      title: 'O que preciso para realizar uma consulta?',
      content:
        'Ao contratar o plano que melhor se encaixe na sua necessidade, você receberá login e senha para acessar o portal. Após logar, para realizar a consulta será necessário apenas o número CPF para Pessoa Física ou CNPJ para Pessoa Jurídica que você deseja consultar.'
    }
  ],
  [
    {
      title:
        'Como a solução Gerenciamento de Carteira aumenta a segurança dos negócios?',
      content:
        'O Gerenciamento de Carteira, é uma plataforma de monitoramento integrada que permite a análise de risco e oportunidades de empresas, clientes ou fornecedores com base em informações completas de dados cadastrais, restritivos e Rating.'
    },
    {
      title: 'Em qual contexto eu devo usar a Análise de Carteira?',
      content:
        'Se a empresa tem uma base de clientes que deseja entender para se comunicar de forma mais efetiva e ofertar produtos e serviços de forma segmentada e orientada, podendo ser cross-selling e up-selling. Além disto, é possível descobrir oportunidades de negócio ainda não exploradas, compreender profundamente os perfis de clientes e otimizar as iniciativas e estratégias com base nessa visão.'
    },
    {
      title: 'O que é Cadastro Positivo?',
      content:
        'O Cadastro Positivo é o banco de dados com informações de pagamento dos compromissos financeiros e pagamentos relativos às operações de crédito e aos serviços continuados dos consumidores (PF ou PJ).'
    },
    {
      title: 'Quais são as soluções de prospecção da Equifax | BoaVista? ',
      content:
        'A principal solução de prospecção é o Bluebox, uma base de dados que contém todos os CNPJs e CPFs ativos do país. Com o Bluebox, você pode segmentar e gerar listas de leads, enriquecer esses leads e vender de maneira mais eficiente e inteligente. Além disso, a ferramenta permite realizar análises rápidas que aprimoram sua prospecção de clientes.'
    },
    {
      title: 'O que são os serviços de Recuperação de Clientes?',
      content:
        'A Equifax | BoaVista oferece serviços de negativação e notificação de clientes inadimplentes, aumentando a chance de recuperação das dívidas que esses clientes têm com sua empresa.'
    }
  ]
]
