export const HIRING_STEPS = [
  {
    icon: 'equifax-pointer-check',
    description: 'Selecione o plano'
  },
  {
    icon: 'equifax-file-export',
    description: 'Envie seus dados'
  },
  {
    icon: 'equifax-letter',
    description: 'Receba o e-mail de acesso'
  },
  {
    icon: 'equifax-portal',
    description: 'Acesso disponível'
  },
  {
    icon: 'equifax-bill',
    description: 'Receba e pague o boleto'
  }
]
